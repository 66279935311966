

var contentEN = {
  "aboutme" : {
    "name" : "About me",
    "content" : 
<>
<p>Hi 👋, I am Sagar Vadnere,</p>
<p>
A Software Engineer with 7+ years of experience specializing in Web Development, JavaScript, and React. Started with solving problems and being everyone's go-to guy for technology-related issues in school and college. Today so far nothing much has changed. I am still the first point of contact for technical difficulties.
</p>
<p>
Along with that, I write code to build Scalable, Robust, and Secure Websites. I also like to solve production bugs with high severity. Recently, I have been helping clients to fix technical SEO issues to improve page speed insights scores and overall website performance. 
</p>
<p>
I write scripts in Python to automate any manual work for me or my colleagues. I have also had some experience in hardening web servers and penetration testing for websites. I have always been an enthusiast for Cyber Security, and Open Source technologies.
</p>
<p>
I am always passionate about giving more to the community, and sharing knowledge has always motivated me. I am curious about how technology is changing the world.
</p>
</>
  },


  "skills" : {
    "name" : "Skills", 
    "content" : 
<>
<p>
Specialties: React, JavaScript, Git, SQL, PHP, HTML/CSS, Technical SEO, Website Performance Optimization, Frontend Development, WordPress, Shopify, Custom CMS, and Python
</p>
<p>
Besides, I know concepts of functional programming, which I learned from the Python, PHP, and JavaScript. 
</p>

</>
  },


  "contact" : {
    "name" : "Contact",
    "content" : 
<>
<p>Please feel free to get in touch if you want to know more about me.</p>
<p>Here is my contact info:</p>
<p> 💻 <a rel="noreferrer" target="_blank" href="https://github.com/sagarvadn">GitHub</a></p>
<p> 👔 <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/sagarvadn/">LinkedIn</a></p>
<p> 📷 <a rel="noreferrer" target="_blank" href="https://www.instagram.com/sagar.vadnere/">Instagram</a></p>
</>
  }
}


export default contentEN
